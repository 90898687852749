<template>
  <div class="help-center-section" v-if="section">
    <h2 class="help-center-section--title">{{ section.title }}</h2>
    <ul>
      <li v-for="topic in section.topics" :key="topic.id">
        <router-link
          :to="`/help-center-all/topic/${topic.slug}`"
          class="help-center-section--topic"
        >
          {{ topic.title }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'HelpCenterSection',
  computed: {
    ...mapState({
      sections: state => state.helpCenter.sections,
      section: state => state.helpCenter.section,
    }),
    currentSectionId () {
      return Number(this.$route.params.sectionId)
    },
  },
  created () {
    this.$store.dispatch('selectSectionById', this.currentSectionId)
  },
  watch: {
    currentSectionId () {
      this.$store.dispatch('selectSectionById', this.currentSectionId)
    },
  },
}
</script>

<style scoped lang="scss">
.help-center-section {
  &--title {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.01em;
    margin-bottom: 20px;
    color: $text-black;
  }

  &--topic {
    display: inline-block;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 16px;
    color: $color-primary;
    transition: 0.3s ease color;

    &:hover {
      color: $color-primary-hover;
    }
  }
}
</style>
